<template>
  <div>
    <b-card>
      <b-card-title>
        <b-row >
          <b-col xl="10" md="9" lg="10" class="mt-1">
            <h3 >
                Eventos de {{this.carbonFarms.farmName}}
            </h3>
          </b-col>
          <b-col xl="2" md="3" lg="2" style="margin-top:5px;text-align:right">                         
            <b-button v-if="userType != 'AuditorCarbono'" variant="primary" block @click="createEvent">                        
              <feather-icon icon="PlusIcon" class="mr-50"/>
              <span class="align-middle">Crear evento</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-title>
      <b-table                 
        ref="selectableTable"
        :fields="fields" 
        :items="farmEventsDetails"                
        responsive
        selectable
        class="mb-0"
        :select-mode="selectMode"
        @row-selected="onRowSelected">
        
        <template #cell(eventDate)="data">
            {{((data.item.eventDate).substring(0,10))}}                    
        </template>
      </b-table>

      <b-row v-if="this.farmEventsDetails.length <= 0"  class="justify-content-center mt-3" style="text-align:center">
        <b-col style="justify-content:center" xl="4" md="6" lg="4"  >
          <b-alert variant="primary" show>
            <div class="alert-body">
              <span><strong>No Hay Eventos para mostrar</strong></span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>

    <b-modal size="sm" ref="create-new-event" id="create-new-event" centered hide-footer title="Digita la siguiente información">            
      <b-card-text>                 
        <b-form>
          <b-row>
            <b-col md="12" xl="12" class="mb-0">
              <b-form-group label="Evento" label-for="basicInput">
                <b-form-select v-model="eventType" 
                  :options="optionsEvents">
                  <b-form-select-option :value="null">Escoja un Evento...</b-form-select-option>
                </b-form-select>
            </b-form-group>                       
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" xl="12" class="mb-0">
              <label for="example-datepicker">Descripción</label>
              <b-form-textarea v-model="description" id="textarea-default" rows="5"/>
            </b-col>   
          </b-row>
          <b-row>
            <b-col md="12" xl="12" class="mb-0">
              <label for="example-datepicker">Fecha Evento</label>
              <b-form-datepicker v-model="eventDate" id="example-datepicker1" class="mb-1"/>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col>
              <h6>
                Link
              </h6>
            </b-col>                        
          </b-row>

          <b-row>
            <b-col md="12" xl="12" class="mb-1">                            
              <b-form-input v-model="link" placeholder="Copia y pega el link de youtube"/>                            
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <h6>
                Carga de Videos
              </h6>
            </b-col>                        
          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-0">
              <b-form-group label="Video 1" label-for="basicInput">
                <b-form-file placeholder="Seleccione" v-model="eventVideo1File" />
                <b-card-text @click="openVideo(eventSelected.eventVideo1Url)" v-if="eventSelected.eventVideo1Url" class="cursor-pointer sosty-font-smaller" style="color:#00BD56">Ver<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
              </b-form-group>
            </b-col>
              <b-col md="6" xl="6" class="mb-0">
                <b-form-group label="Video 2" label-for="basicInput">
                  <b-form-file placeholder="Seleccione" v-model="eventVideo2File" />
                  <b-card-text @click="openVideo(eventSelected.eventVideo2Url)" v-if="eventSelected.eventVideo2Url" class="cursor-pointer sosty-font-smaller" style="color:#00BD56">Ver<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                </b-form-group>
              </b-col>
          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-0">
              <b-form-group label="Video 3" label-for="basicInput">
                <b-form-file placeholder="Seleccione" v-model="eventVideo3File" />
                <b-card-text @click="openVideo(eventSelected.eventVideo3Url)" v-if="eventSelected.eventVideo3Url" class="cursor-pointer sosty-font-smaller" style="color:#00BD56">Ver<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
              </b-form-group>
            </b-col>
          </b-row>
          
          <b-row class="mt-2">
            <b-col>
              <h6>
                Carga de Imágenes
              </h6>
            </b-col>                        
          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-0">
              <b-form-group label="Imagen 1" label-for="basicInput">
                <b-form-file placeholder="Seleccione" v-model="picture1File" accept=".jpg,.png"/>
                <b-card-text @click="openDocument(eventSelected.picture1Url)" v-if="eventSelected.picture1Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-0">
              <b-form-group label="Imagen 2" label-for="basicInput">
                <b-form-file placeholder="Seleccione" v-model="picture2File" accept=".jpg,.png" />
                <b-card-text @click="openDocument(eventSelected.picture2Url)" v-if="eventSelected.picture2Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-0">
              <b-form-group label="Imagen 3" label-for="basicInput">
                <b-form-file placeholder="Seleccione" v-model="picture3File" accept=".jpg,.png" />
                <b-card-text @click="openDocument(eventSelected.picture3Url)" v-if="eventSelected.picture3Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-0">
              <b-form-group label="Imagen 4" label-for="basicInput">
                <b-form-file placeholder="Seleccione" v-model="picture4File" accept=".jpg,.png"/>
                <b-card-text @click="openDocument(eventSelected.picture4Url)" v-if="eventSelected.picture4Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-0">
              <b-form-group label="Imagen 5" label-for="basicInput">
                <b-form-file placeholder="Seleccione" v-model="picture5File" accept=".jpg,.png" />
                <b-card-text @click="openDocument(eventSelected.picture5Url)" v-if="eventSelected.picture5Url" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <h6>
                Carga de Archivos
              </h6>
            </b-col>                        
          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-0">
              <b-form-group label="Archivo 1" label-for="basicInput">
                <b-form-file placeholder="Seleccione" v-model="eventFile1File" accept=".pdf"/>
                <b-card-text @click="openDocument(eventSelected.eventFile1Url)" v-if="eventSelected.eventFile1Url" class="cursor-pointer sosty-font-smaller">abrir/descargar<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-0">
              <b-form-group label="Archivo 2" label-for="basicInput">
                <b-form-file placeholder="Seleccione" v-model="eventFile2File" accept=".pdf"/>
                <b-card-text @click="openDocument(eventSelected.eventFile2Url)" v-if="eventSelected.eventFile2Url" class="cursor-pointer sosty-font-smaller">abrir/descargar<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-0">
              <b-form-group label="Archivo 3" label-for="basicInput">
                <b-form-file placeholder="Seleccione" v-model="eventFile3File" accept=".pdf"/>
                <b-card-text @click="openDocument(eventSelected.eventFile3Url)" v-if="eventSelected.eventFile3Url" class="cursor-pointer sosty-font-smaller">abrir/descargar<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row  class="mt-1 justify-content-end">
            <b-col v-if="!processing" class="mt-2" md="4" lg="4">
              <b-button variant="primary" @click="saveCarbonFarmEvent" block>                                                            
                Guardar
              </b-button>
            </b-col>
            <b-col v-if="processing" class="mt-2" md="4" lg="4">
              <b-button variant="primary" disabled block>
                <b-spinner small label="Cargando..." />
              </b-button>
            </b-col>
          </b-row>                    
        </b-form>                
      </b-card-text>        
    </b-modal>

    <b-modal size="sm" ref="edit-delete-event" centered  id="edit-delete-event" hide-footer title="Que deseas hacer?">                                                        
      <b-col xl="12" md="12" lg="12">            
        <b-row  style="text-align:center">                
          <b-button class="mt-1" variant="primary" block @click="editEvent">
            <feather-icon icon="EditIcon" class="mr-50"/>
            <span class="align-middle">Editar Evento</span>                                    
          </b-button>                
        </b-row>
        <b-row style="text-align:center">
          <b-button class="mt-1" variant="warning" block @click="deleteEvent">
            <feather-icon icon="DeleteIcon" class="mr-50"/>
            <span class="align-middle">Borrar Evento</span>                                    
          </b-button>                
        </b-row>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol,  BLink, BTable, BCalendar, BFormDatepicker, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,  
  BCard, BModal, BAlert, BFormSelect, BFormSelectOption, BFormFile, BFormTextarea
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import axios from '@axios'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
export default {

components: {
    BRow,
    BAlert,
    BCardCode,
    BCalendar,
    BCol,
    BTable,
    BLink,
    BCard,
    BFormDatepicker,
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormFile,     
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,    
    BSpinner,
    Cleave,
    Ripple,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    },
    data(){
      return{
        processing: false,
        carbonFarmID: null,
        farmEventsDetails:{},
        carbonFarms:{},
        farmEventsDetails:[],
        fields:[
            {key: 'eventDate', label: 'Fecha Evento'},
            {key: 'eventType', label: 'Evento'},
            {key: 'description', label: 'Descripción'},
        ],
        eventSelected: {},          
        selectMode: 'single',
        eventType: null,
        description: null,
        eventDate: null,
        picture1Url: null,
        picture2Url: null,
        picture3Url: null,
        picture4Url: null,
        picture5Url: null, 
        eventFile1Url: null,
        eventFile2Url: null,
        eventFile3Url: null,
        payload:{},
        carbonFarmEvent:{},
        carbonFarmEventID: null,
        picture1File: null,
        picture2File: null,
        picture3File: null,
        picture4File: null,
        picture5File: null,
        eventFile1File: null,
        eventFile2File: null,
        eventFile3File: null,
        eventVideo1Url: null,
        eventVideo2Url: null,
        eventVideo3Url: null,
        link: null,
        eventVideo1File: null,
        eventVideo2File: null,
        eventVideo3File: null,
        videoUrl: null,
        url: null,
        optionsEvents : ['Actualización General', 'Cargue Documentos',
                          'Venta de Ganado', 'Traslado de Animales',
                          'Rotación de Pasturas', 'Quema/Incendio',
                          'Conflicto de Tierra', 'Aforo',
                          'Backup', 'Avistamiento Biodiversidad (Macrofauna)',
                          'Avistamiento Biodiversidad (Microfauna)', 
                          'Rotación Ganado', 'Protección Bosques',
                          'Mejoramiento Infraestructura', 'Otro...' ],
        userType: '',
      }
    },

    created(){
      if(this.$route != null && this.$route.query != null && this.$route.query.carbonFarmID != null) {
          this.carbonFarmID = this.$route.query.carbonFarmID;
          var userData = getUserData();
          this.userType = userData.userType;
          console.log('User Type:..', this.userType);
  
      this.getFarmEvents();
      this.getCarbonFarmByID();
      } 
    },

    watch:{
      carbonFarmEventID: function(val) { this.carbonFarmEventID = val; },
      picture1File: function(val) { this.picture1File = val; this.uploadFile(val, 'picture1File'); },
      picture2File: function(val) { this.picture2File = val; this.uploadFile(val, 'picture2File'); },
      picture3File: function(val) { this.picture3File = val; this.uploadFile(val, 'picture3File'); },
      picture4File: function(val) { this.picture4File = val; this.uploadFile(val, 'picture4File'); },
      picture5File: function(val) { this.picture5File = val; this.uploadFile(val, 'picture5File'); },
      eventFile1File: function(val) { this.eventFile1File = val; this.uploadFile(val, 'eventFile1File'); },
      eventFile2File: function(val) { this.eventFile2File = val; this.uploadFile(val, 'eventFile2File'); },
      eventFile3File: function(val) { this.eventFile3File = val; this.uploadFile(val, 'eventFile3File'); },
      eventVideo1File: function(val) {this.eventVideo1File = val; this.uploadVideo(val, 'eventVideo1File')},
      eventVideo2File: function(val) {this.eventVideo2File = val; this.uploadVideo(val, 'eventVideo2File')},
      eventVideo3File: function(val) {this.eventVideo3File = val; this.uploadVideo(val, 'eventVideo3File')}
    },

    methods:{
      
      getFarmEvents(){
        this.processing = true;
        axios.get('/CarbonFarm/GetCarbonFarmEvents', { params: { carbonFarmID: this.carbonFarmID }})
        .then(response => {
            this.farmEventsDetails = response.data;            
        })
      },

      getCarbonFarmByID() {
        axios.get('/CarbonFarm/GetCarbonFarmByID', { params: { carbonFarmID: this.carbonFarmID }})
        .then(response => {
            this.carbonFarms = response.data;
        })
      },

      getCarbonFarmEventByID(){
        this.carbonFarmEventID = this.eventSelected.carbonFarmEventID             
        axios.get('/CarbonFarm/GetCarbonFarmEventByID', { params: { carbonFarmEventID: this.carbonFarmEventID}})
        .then(response => {
            this.eventSelected = response.data;
            this.carbonFarmEventID = this.eventSelected.carbonFarmEventID
            this.carbonFarmID = this.eventSelected.carbonFarmID
            this.eventType = this.eventSelected.eventType
            this.description = this.eventSelected.description
            this.eventDate = this.eventSelected.eventDate
            this.picture1Url = this.eventSelected.picture1Url
            this.picture2Url = this.eventSelected.picture2Url
            this.picture3Url = this.eventSelected.picture3Url
            this.picture4Url = this.eventSelected.picture4Url
            this.picture5Url = this.eventSelected.picture5Url
            this.eventFile1Url = this.eventSelected.eventFile1Url
            this.eventFile2Url = this.eventSelected.eventFile2Url
            this.eventFile3Url = this.eventSelected.eventFile3Url  
            this.eventVideo1Url = this.eventSelected.eventVideo1Url
            this.eventVideo2Url = this.eventSelected.eventVideo2Url
            this.eventVideo3Url = this.eventSelected.eventVideo3Url     
            this.link = this.eventSelected.link              

            this.hideEditDeleteModal();
            this.showEventModal();
        })
      },
      
      createEvent(){               
        if(this.eventSelected.carbonFarmEventID != null || this.eventSelected.carbonFarmEventID != undefined){                
          this.carbonFarmID = null,
          this.eventType = null,
          this.description = null,
          this.eventDate = null,
          this.picture1Url = null,
          this.picture2Url = null,
          this.picture3Url = null,
          this.picture4Url = null,
          this.picture5Url = null,
          this.eventFile1Url = null,
          this.eventFile2Url = null,
          this.eventFile3Url = null,
          this.eventVideo1Url = null,
          this.eventVideo2Url = null,
          this.eventVideo3Url = null,
          this.link = null
        }
        this.showEventModal()
      },        
      
      saveCarbonFarmEvent(){   
        this.processing = true;         
        if(!this.eventType || !this.eventDate || !this.description){
          this.$bvToast.toast("Debes diligenciar Evento, Fecha de Evento y Descripción", { title: 'Error', variant: 'danger', solid: true, })
          this.processing = false;
        } else {                    
          this.eventSelected.carbonFarmID = this.carbonFarmID  
          this.eventSelected.eventType = this.eventType,
          this.eventSelected.description = this.description,
          this.eventSelected.eventDate = this.eventDate,
          this.eventSelected.link = this.link

          var payload = {
            eventSelected: this.eventSelected
          }                
      
          axios.post('/CarbonFarm/SaveCarbonFarmEvent', payload)
          .then(response => {
            this.processing = false;
            if(response.data) {     
              this.$bvToast.toast("Información guardada de forma exitosa", { title: 'Ok', variant: 'success', solid: true, });  
              this.carbonFarms = response.data;   
              this.hideEventModal();       
            } else {
              this.$bvToast.toast("Ocurrio un problema", { title: 'Error', variant: 'danger', solid: true, })     
            }                    
            window.location.reload()
          })            
        }
      }, 
      
      editEvent(){
        this.getCarbonFarmEventByID()
      },

      openDocument(fileUrl) {
        window.open(fileUrl, '_blank');
      },
      
      onRowSelected(items) {
        this.eventSelected = items[0];
        if(this.eventSelected){
            this.showModalToEditDelete() 
        }
      },

      showEventModal() {     
        this.processing = false,     
        this.$refs['create-new-event'].show()         
      },

      hideEventModal(){
        this.$refs['create-new-event'].hide()                  
      },

      showModalToEditDelete() {           
        this.$refs['edit-delete-event'].show()         
      },

      hideEditDeleteModal(){
        this.$refs['edit-delete-event'].hide() 
      },
      
      deleteEvent(){
        this.carbonFarmEventID = this.eventSelected.carbonFarmEventID 
        axios.get('/CarbonFarm/DeleteEvent', { params: { carbonFarmEventID: this.carbonFarmEventID}})
        .then(response => {                 
          this.processing = false;
          if(response.data) {     
            this.$bvToast.toast("El Evento ha sido borrado", { title: 'Ok', variant: 'success', solid: true, });  
            this.carbonFarms = response.data;
            this.hideEditDeleteModal();        
          } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Error', variant: 'danger', solid: true, })     
          }
          window.location.reload()   
        })
      },

      uploadFile(fileToUpload, fileType) {
        console.log({
          fileToUpload,fileType
        })
        this.processing = true;               
        let formData = new FormData();
        formData.append('file', fileToUpload);
        axios.post('/File/UploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
        this.processing = false;
        if(response.data) {                
          this.setFileUrl(fileType, response.data);
          this.$bvToast.toast("Documento subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });            
          }
          else {
          this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
          }
        })
        .catch(error => {
        this.processing = false;
          if(error.response){
          this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
          }
          else {
          this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
          }
        })
      },

      setFileUrl(fileType, fileUrl) {            
        switch(fileType) {
          case 'picture1File':
              this.eventSelected.picture1Url = fileUrl;
              break;
          case 'picture2File':
              this.eventSelected.picture2Url = fileUrl;
              break;
          case 'picture3File':
              this.eventSelected.picture3Url = fileUrl;
              break;
          case 'picture4File':
              this.eventSelected.picture4Url = fileUrl;
              break;
          case 'picture5File':
              this.eventSelected.picture5Url = fileUrl;
              break;
          case 'eventFile1File':
              this.eventSelected.eventFile1Url = fileUrl;
              break;
          case 'eventFile2File':
              this.eventSelected.eventFile2Url = fileUrl;
              break;
          case 'eventFile3File':
              this.eventSelected.eventFile3Url = fileUrl;
              break;
          case 'eventVideo1File':
              this.eventSelected.eventVideo1Url = fileUrl;
              break;
          case 'eventVideo2File':
              this.eventSelected.eventVideo2Url = fileUrl;
              break;
          case 'eventVideo3File':
              this.eventSelected.eventVideo3Url = fileUrl;
              break;                    
          }
      },        
      
      uploadVideo(fileToUpload, fileType) {
        this.processing = true;               
        let formData = new FormData();            
        formData.append('file', fileToUpload);
        axios.post('/File/UploadVideo', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
        this.processing = false;
        if(response.data) {                
          this.setFileUrl(fileType, response.data);
          this.$bvToast.toast("Video subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });
          }
        else {
          this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
        }
        })
        .catch(error => {
        this.processing = false;
          if(error.response){
          this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
          }
          else {
          this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
          }
        })
      },

      openVideo(url){            
        this.url = url
        window.open(this.url, '_blank');
      }
    }
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>